import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import FooterMenu from './FooterMenu'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import twitterIcon from '../images/twitter.png'
import InstagramIcon from '../images/instagram.svg'
import facebookIcon from '../images/facebook.png'

const Footer = () => {
	const data = useStaticQuery(graphql`
		query HeadingQuery {
			site {
				siteMetadata {
					title
				}
			}
			prismicTopMenu {
				...TopMenuFragment
			}
		}
	`)

	return (
		<footer>
			<div className='footer__column'>
				<FooterMenu footerMenu={data.prismicTopMenu.data} />
				<div className='copyright__wrapper'>
					<p className='copyright'>
						<span>© Copyright {new Date().getFullYear()} - </span>
						<span>{data.site.siteMetadata.title}</span>
					</p>
				</div>
				<div className='social'>
					<a href='https://instagram.com/betheuinunique/' target='_blank'>
						<p>Follow me on instagram:</p>
						<InstagramIcon />
					</a>
				</div>
			</div>
		</footer>
	)
}

export default Footer
