import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { RichText } from 'prismic-reactjs'
import Icon from '../images/svg/betheuinunique-logo.svg'

import { gsap } from 'gsap'

const TopMenu = ({ topMenu, activeDocMeta }) => {
	const mobileRef = useRef(null)
	const [menuOpen, isMenuOpen] = useState(false)

	const toggleActiveClass = (event) => {
		isMenuOpen((prevCheck) => !prevCheck)
		if (window.innerWidth < 992) {
			document.querySelector('html').classList.toggle('js-nav-main-expanded')
			if (menuOpen === false) {
				gsap.set(mobileRef.current.children[0].children, {
					autoAlpha: 0,
					x: 20,
					overwrite: true,
				})
				gsap.to(mobileRef.current, {
					x: 0,
					duration: 0.3,
					overwrite: true,
					ease: 'power1.out',
					onComplete: () => {
						gsap.set
						gsap.to(mobileRef.current.children[0].children, {
							autoAlpha: 1,
							x: 0,
							stagger: 0.08,
							duration: 0.6,
							ease: 'power1.out',
						})
					},
				})
			} else {
				gsap.to(mobileRef.current.children[0].children, {
					autoAlpha: 0,
					x: 20,
					stagger: {
						from: 'end',
						amount: 0.08,
					},
					duration: 0.4,
					overwrite: true,
					ease: 'power1.in',
					onComplete: () => {
						gsap.set(mobileRef.current.children[0].children, {
							clearProps: 'all',
						})
					},
				})
				gsap.to(mobileRef.current, {
					x: '100%',
					duration: 0.3,
					overwrite: true,
					ease: 'power1.in',
					onComplete: () => {
						gsap.set(mobileRef.current, { clearProps: 'all' })
					},
				})
			}
		}
	}

	const renderedMenuLinks = topMenu.menu_links
		? topMenu.menu_links.map((menuLink, index) => (
				<li key={`top-nav-${index}`}>
					<AniLink
						cover
						direction='right'
						bg='#f8b8b5'
						duration={1.5}
						id={menuLink.link.id}
						to={menuLink.link.url}
						partiallyActive={true}
						activeClassName='active'
						onClick={toggleActiveClass}>
						{RichText.asText(menuLink.label.raw)}
					</AniLink>
				</li>
		  ))
		: null

	return (
		<header>
			<div className='navigation'>
				<div className='navigation__logo'>
					<AniLink cover direction='right' bg='#f8b8b5' duration={1.5} to='/'>
						<Icon className='logo' />
					</AniLink>
				</div>
				<div
					className='navigation__toggler || js-nav-main-toggler'
					onClick={toggleActiveClass}>
					<span className='navigation__toggler-el -el1'></span>
					<span className='navigation__toggler-el -el2'></span>
					<span className='navigation__toggler-el -el3'></span>
				</div>
				<div className='navigation__menu' ref={mobileRef}>
					<ul className='typ'>{renderedMenuLinks}</ul>
				</div>
			</div>
		</header>
	)
}

export const query = graphql`
	fragment TopMenuFragment on PrismicTopMenu {
		type
		lang
		data {
			menu_links {
				label {
					raw
					html
					text
				}
				link {
					id
					url
				}
			}
		}
	}
`

export default TopMenu
