import React from 'react'
import { graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { RichText } from 'prismic-reactjs'

const FooterMenu = ({ footerMenu }) => {
	const renderedMenuLinks = footerMenu.menu_links
		? footerMenu.menu_links.map((menuLink, index) => (
				<li key={`top-nav-${index}`}>
					<AniLink
						cover
						direction='right'
						bg='#f8b8b5'
						duration={1.5}
						id={menuLink.link.id}
						to={menuLink.link.url}
						partiallyActive={true}
						activeClassName='active'>
						{RichText.asText(menuLink.label.raw)}
					</AniLink>
				</li>
		  ))
		: null

	return (
		<div className='footer__menu'>
			<ul className='typ'>{renderedMenuLinks}</ul>
		</div>
	)
}

export const query = graphql`
	fragment TopMenuFragment on PrismicTopMenu {
		type
		lang
		data {
			menu_links {
				label {
					raw
					html
					text
				}
				link {
					id
					url
				}
			}
		}
	}
`

export default FooterMenu
